/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas'
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import powerpointIcon from '../../images/powerpoint-icon.png';
import PptxGenJS from 'pptxgenjs';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import { BaseURL } from '../../Constant';

var recordCount = 0;

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row"
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        let circle = document.createElement('div');
        circle.className = "circleDivCompare";
        spanValue.textContent = params.value;
        if (params.data.DealName.trim() != i18n.t('benchmark_CompareScreen')) {
            if (params.value != "" && params.value >= 0 && params.value < 2) {
                circle.style.backgroundColor = "#E57373"
            }
            else if (params.value != "" && params.value >= 2 && params.value < 3) {
                circle.style.backgroundColor = "#FFB74D"
            }
            else if (params.value != "" && params.value >= 3 && params.value < 3.5) {
                circle.style.backgroundColor = "#FFEB3B"
            }
            else if (params.value != "" && params.value >= 3.5 && params.value <= 4) {
                circle.style.backgroundColor = "#81C784"
            }
            this.eGui.appendChild(circle);
            this.eGui.appendChild(spanValue);
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.getGui = function (params) {
    return this.eGui;
}

class BOILITrendReport extends React.Component {
    constructor(props) {
        super(props);
        
        try {
            recordCount = 0;
               this.state = {
                headerValue: "",
                prevAssessmentGridData: [],
                disableCompare: true,
                showComparePopup: false,
                headerValue: "",
                lstDeal: [],
                DealValue: [],
                ProcessValue:[],
                lstprocess:[],
                IndustryValue: "",
                lstIndustry: [],
                lstRegion: [],
                MainOfferingValue: [],
                lstMainoffering:[],
                lstCapability: [],
                lstCountry: [],
                lstTower: [],
                lstServiceComp: [],
                lstDlvryLoc: [],
                lstLever: [],
                IndustryValue: "",
                RegionValue: [],
                OGIndustry: "",
                OG: "",
                Industry: "",
                CapabilityValue: [],
                CountryValue: { label: i18n.t("all"), value: "0" },
                TowerValue: [],
                ServiceCompValue: [],
                DlvryLocValue: { label: i18n.t("all"), value: "0" },
                eblNxtBTn: false,
                Confirm: false,
                message: "",
                DraftID: 0,
                comparisionGridData: [],
                popUpLandscapeGraph: false,
                GraphReportArray: [],
                AllOverallScoreArray: [],
                AllStandardizationScoreArray: [],
                AllDealnameArray: [],
                AllRegionArray: [],
                nonEmptyLeverNames: [],
                OMIDNewValue: "",
                clientName: "",
                Market: "",
                MainOffering: "",
                Offering_SSG: "",
                SubOffering: "",
                defaultProcessName: [],
                Process_Id: 0,
                MainOfferingid: 0,
                subOfferingId: 0,
                Industry_Id: 0,
                Marketid: 0,
                Country_Id: 0,
                ProcessName: [],
                City_Id: 0,
              };  
              this.HandleDropdownValues = this.HandleDropdownValues.bind(this);
              this.onDealChange = this.onDealChange.bind(this);
              this.SelectMarket =this.SelectMarket.bind(this);
              this.SelectProcess = this.SelectProcess.bind(this);
              this.SelectTower = this.SelectTower.bind(this);
              this.SelectMainOffering = this.SelectMainOffering.bind(this);                                                        
            this.reSizeGrid = this.reSizeGrid.bind(this);
      this.closepopUpLandscapeGraph = this.closepopUpLandscapeGraph.bind(this);
      this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
      this.AcrossDealComparision = this.AcrossDealComparision.bind(this);
      this.handleOMIDChange = this.handleOMIDChange.bind(this);
      this.ValidateOMID = this.ValidateOMID.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        this.HandleDropdownValues();
        try {
            $("#loader-div").show();
            window.addEventListener('resize', this.reSizeGrid, true);
            let marketData ={
                GroupID: this.props.groupId,
               LanguageID: this.props.languageId};
               request("/Common/GetMarket", "POST", marketData)
               .then((response) => {
                console.log("exce",response)
                 response.length > 0
                   ? this.setState({ lstMarket: response })
                   : this.setState({ lstMarket: [] });
                 $("#loader-div").hide();
               })
               .catch((error) => {
                 console.log(error);
                 $("#loader-div").hide();
               });
            // var DealID = 0;
            // var SCID = 0;
            // var DeliveryLocationId = 0;
            let dealData = {
                "DealId": 0,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId 
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
     let userData = {
          UserName: this.props.enterpriseId,
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };
        request("/Common/GetReportAccessByUser", "POST", userData)
          .then((response) => {
            response.Table.length > 0
              ? this.setState({ isReport: response.Table[0].IsReport })
              : this.setState({ isReport: "" });
            $("#loader-div").hide();
          })
          .catch((error) => {
            console.log(error);
            $("#loader-div").hide();
          });

            request("/MasterScreens/GetSCBasedOnTower?SCId=0&TowerId=0&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
            .then(response => {
                    response.length > 0 ? this.setState({ lstServiceComp: response }) : this.setState({ lstServiceComp: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            var SearchedString = "";
            let BOILIMasteData ={
                TowerId:0,
                BOILIFlag:3,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/BOI/GetBOILIMasterForDropDown", 'POST', BOILIMasteData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstBOILI: response }) : this.setState({ lstBOILI: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    HandleDropdownValues(val) {
        fetch(
          BaseURL + "/Common/GetOMIdMainOffering",
    
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            function getMainOfferingValue(data) {
              return data.map((item) => {
                return {
                  label: item.main_offering_name,
                  value: item.main_offering_id,
                };
              });
            }
             this.setState({ lstMainoffering: getMainOfferingValue(data)
            });
          })
          .catch((error) => {
            console.log("Error Encountered");
          });
         
           
    
        //const MainOfferingValues = this.state.MainOfferingValue.map((item) => item.value);
        //const FinalValue = MainOfferingValues.find (item => item.value === );
    
      
       
      }
      SelectDeal(val) {
        console.log(val);
        try {
      //    $("#loader-div").show();
          this.setState({
            showComparePopup: false,
            lstServiceComp: [],
            ServiceCompValue: [],
            lstCapability: [],
            CapabilityValue: [],
            lstTower: [],
            TowerValue: [],
          });
          if (val.length != 0) {
            this.setState({
              DealValue: val,
    
              prevAssessmentGridData: [],
            });
            let dealData = {
              UserName: this.props.enterpriseId,
              DeliveryLocationId: 0,
              DealId: val[0].value,
              PermissionLevel: "3,2,1",
              GroupID: this.props.groupId,
              LanguageID: this.props.languageId,
            };
            this.setState({ClientId:val[0].value});
            request("/Common/DLDropDownBasedOnUserAccess", "POST", dealData)
              .then((response) => {
                response.length > 0
                  ? this.setState({ lstDlvryLoc: response })
                  : this.setState({ lstDlvryLoc: [] });
                $("#loader-div").hide();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.ClearFilter();
            $("#loader-div").hide();
          }
        } catch (error) {
          console.log("Error Encountered");
        }
      }

    // SelectRegion(val) {
    //     try {
    //         this.setState({ RegionValue: val });
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }

    // SelectServiceComponent(val) {
    //     try {
    //         this.setState({ ServiceCompValue: val });
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }

    // SelectDlvryLoc(val) {
    //     try {
    //         this.setState({ DlvryLocValue: val });
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }
    SelectMarket(val) {
        try {
            $("#loader-div").show();
           
    console.log("va",val)
            if (val.length != 0) {
              this.setState({
                MarketValue: val,
      
                prevAssessmentGridData: [],
              });
                $("#loader-div").show();
                let marketData = {
                    
                    "GroupID":this.props.groupId,
                    "LanguageID":this.props.languageId,
                    
                }
                this.setState({Marketid:val[0].value});
                request("/Common/GetMarket", 'POST', marketData)
                    .then(response => {
                      console.log("exc",response)
                      response.length > 0
                       ?this.setState({ lstMarket: response })
                       :this.setState({  lstMarket: [] });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
              this.ClearFilter();
              $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
            $("#loader-div").hide();
        }
    }
    
    async  SelectMainOffering(val) {
        if (val.length  === 0) {
        
          this.setState({
            MainOfferingid: 0,
            mainOfferingName: "",
    
          })
        }
    
       
        else{
      await  this.setState({
        MainOfferingid: val[0].value,
          MainOfferingValue:val,
          mainOfferingName: val[0].label,
      
        });
      
    
        console.log("mainOfferingId2",this.state.mainOfferingId);
       // this.setState({MainOfferingid});
        fetch(
          BaseURL +
            "/Common/GetOMIdOfferingSSG?MainOfferingID=" +
            this.state.MainOfferingid,
      
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("dataa",data)
            function getCapabilityValue(data) {
              return data.map((item) => {
                return {
                  label: item.offering_ssg_name,
                  value: item.offering_ssg_id,
                };
              });
            }
            this.setState({ lstCapability: getCapabilityValue(data) });
          })
          .catch((error) => {
            console.log("Error Encountered");
          });
        }
       
      }
    
      async SelectCapability(val) {
        if (val.length === 0) {
          this.setState({
            Industry_Id: 0,
            capabilityName: "",
          });
        }
        else{
          
        await this.setState({
          Industry_Id: val[0].value,
          capabilityName: val[0].label,
          CapabilityValue:val,
        }
      );
        fetch(
          BaseURL +
            "/Common/GetOMIdSubOffering?OfferingSSGID=" +
            this.state.Industry_Id,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("fet",data);
            function getTowerValue(data) {
              return data.map((item) => {
                return {
                  label: item.sub_offering_name,
                  value: item.sub_offering_id,
                };
              });
            }
            this.setState({
               lstTower: getTowerValue(data), 
              },
              () =>{console.log("tower",this.state.TowerValue)}
            );
          })
          .catch((error) => {
            console.log("Error Encountered");
          });
         
    
        }
      }
      async  SelectTower(val) {
        if (val.length === 0) {
          this.setState({
            subOfferingId: 0,
           });
        }
        else{
        await this.setState({
          subOfferingId: val[0].value,
         TowerValue:val,
        },
      ()=>{
        let processData ={
          SubOfferingIds:this.state.subOfferingId,
          GroupID: this.props.groupId,
         LanguageID: this.props.languageId};
    
        
         request("/Common/GetProcessBasedOnSubOffering", "POST", processData)
          .then((response) => {
           response.length > 0
             ? this.setState({ lstprocess: response })
             : this.setState({ lstprocess: [] });
           $("#loader-div").hide();
         })
         .catch((error) => {
          console.log("Error Encountered");
          
           $("#loader-div").hide();
         });
      }
      )
      }
        }
        SelectProcess(val) {
          try {
        
              if (val.length != 0) {
                this.setState({
                  ProcessValue: val,
                  Process_Id:val[0].value,
                  prevAssessmentGridData: [],
                });
                
              }
              else {
                this.ClearFilter();
                $("#loader-div").hide();
              }
          }
          catch (error) {
              console.log("Error Encountered");
          }
        }
        
    SelectBOILI(val) {
        try {
            this.setState({ BOILIValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

   
    handleOMIDChange(e) {
      if (e.target.value == "") {
        this.setState({
          OMIDNewValue: "",
          clientName: "",
          Market: "",
  
          MainOffering: "",
          Offering_SSG: "",
          SubOffering: "",
          defaultProcessName: [],
        });
      } else {
        this.setState({
          OMIDNewValue: e.target.value,
        });
      }
    }

    ValidateOMID() {
      fetch(
        BaseURL + "/Common/GetOMIdDetails1l?OMID=" + this.state.OMIDNewValue,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.setState({
            clientName: data[0].client_name_1,
            Market: data[0].market_name,
            MainOffering: data[0].main_offering_name,
            Offering_SSG: data[0].offering_ssg_name,
            SubOffering: data[0].sub_offering_name,
            City_Id: data[0].city_id,
            ClientId:data[0].client_number,
            Marketid: data[0].market_id,
            OMID: data[0].om_master_id,
            Process_Id: data[0].taxonomy_id,
            MainOfferingid: data[0].main_offering_id,
            subOfferingId: data[0].sub_offering_id,
            Industry_Id: data[0].offering_ssg_id,
            DealValue: [{label: data[0].client_name_1,value: data[0].client_name_1}],
            MarketValue:[{label: data[0].market_name,value: data[0].market_name}],
             MainOfferingValue:[{label: data[0].main_offering_name,value: data[0].main_offering_name}],
             CapabilityValue:[{label: data[0].offering_ssg_name,value: data[0].offering_ssg_name}],
             TowerValue:[{label: data[0].sub_offering_name,value: data[0].sub_offering_name}],
             ProcessValue:[{label: data[0].process_name,value: data[0].process_name}]
          });
        })
        .catch((error) => {
          alert("Please enter an Active OMID to proceed with the PMM Assessment");
          console.log("Error Encountered");
        });
  
      fetch(
        BaseURL +
          "/Common/GetOMIdProcessDetails1l?OMID=" +
          this.state.OMIDNewValue,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          function getProcessName(val) {
            let processName = [];
            val.forEach((element) => {
              processName.push({
                id: element.taxonomy_id,
                label: element.process_name,
              });
            });
            return processName;
          }
          this.setState({
            ProcessName: getProcessName(data),
          });
        })
  
        .catch((error) => {
          console.log("Error Encountered");
        });
    }
    SelectServiceComponent(e) {
      console.log("e.target.value", e.target.name);
  
      if (e.target.value == "") {
        this.setState({
          defaultProcessName: "",
          eblNxtBTn: false,
          ProcessName: [],
        });
      } else {
        console.log(
          "value",
          this.state.ProcessName.find((item) => item.id == e.target.value)
        );
        this.setState({
          defaultProcessName: e.target.value,
          defaultName: this.state.ProcessName.find(
            (item) => item.id == e.target.value
          ).label,
          Process_Id :this.state.ProcessName.find((item) => item.id == e.target.value).id,
          eblNxtBTn: true,
        });
      }
    }

   
    ClearFilter() {
      try {
          $("#bar").css("display", "none");
          $("#line").css("display", "none");
          this.setState({
            disableCompare: true,
            lstIndustry: [],
            lstRegion: [],
            lstCapability: [],
            lstTower: [],
            lstServiceComp: [],
            lstDlvryLoc: [],
            DealValue: [],
            MarketValue:[],
            RegionValue: [],
            CountryValue: { label: i18n.t("all"), value: "0" },
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            SelectMainOffering:"",
            prevAssessmentGridData: "",
            showComparePopup: false,
            comparisionGridData: [],
            OMIDNewValue: "",
            clientName: "",
            Market: "",
            MainOffering: "",
            MainOfferingValue: [],
            Offering_SSG: "",
            SubOffering: "",
            defaultProcessName: [],
            Process_Id: 0,
            MainOfferingid: 0,
            subOfferingId: 0,
            Industry_Id: 0,
            ClientId:0,
            Marketid: 0,
            OMID: 0,
            ProcessName: [],
            City_Id: 0,
            ProcessValue:[],
            BOILIValue:[],
              },()=>{this.HandleDropdownValues()});

          recordCount = 0;
      }
      catch (error) {
          console.log("Error Encountered");
      }
  }

    async DownloadExcel() {
        try {
            var DealID = this.state.DealValue.value == undefined ? 0 : this.state.DealValue.value;
            var RegionID = this.state.RegionValue.value == undefined ? 0 : this.state.RegionValue.value;
            var CountryID = 0;
            var CapabilityID = 0;
            var TowerID = this.state.TowerValue.value == undefined ? 0 : this.state.TowerValue.value;
            var IndustryID = 0;
            var SCID = this.state.ServiceCompValue.value == undefined ? 0 : this.state.ServiceCompValue.value;
            var SearchedString = "";
            let excelData = {
                "PMMProcessID": 0,
                "DealId": DealID,
                "RegionID": RegionID,
                "CountryID": CountryID,
                "CapabilityId": CapabilityID,
                "TowerID": TowerID,
                "IndustryID": IndustryID,
                "SCId": SCID,
                "SearchedString": SearchedString,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            let jsonexcelData = JSON.stringify(excelData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelSubmittedAssessment", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonexcelData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
                
            // await request("/Common/DownloadtoExcelSubmittedAssessment", 'POST', excelData)
            //     .then(response => {
            //         var filePath = response.Table1[0].Value;
            //         window.location.href = filePath;
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    BarChart() {
        try {
            $("#bar").css("display", "block");
            $("#line").css("display", "none");
            if (window.bar_chart && window.bar_chart !== null) {
                window.bar_chart.destroy();
            }
            Chart.defaults.global.plugins.datalabels.display = false;
            var bar_ctx = document.getElementById('bar').getContext('2d');

            window.bar_chart = new Chart(bar_ctx, {
                type: 'bar',
                data: {
                    labels: this.state.datasetNames,
                    datasets: this.state.chartData
                },
                options: {
                    legend: {
                        display: false,
                        labels: {
                            fontFamily: "Comic Sans MS"
                        }
                    },
                    title: {
                        display: true,
                        text: i18n.t('acrossDealComparision'),
                        fontSize: 15,
                        fontFamily: "Comic Sans MS",
                        fontStyle: "bold",
                        fontColor: "#f7701e"
                    },
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5,
                            maxBarThickness: 10,
                            minBarLength: 2,
                            gridLines: {
                                offsetGridLines: true
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 80,
                                minRotation: 80
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    AcrossDealComparision() {
        try {
          if ((this.state.OMIDNewValue === "" || this.state.OMIDNewValue == null) && 
          (this.state.DealValue === "" || this.state.DealValue == null || this.state.DealValue.length === 0))
           {
          alert(i18n.t("pleaseselectOMID//client"));
          return false;
      }
            if (this.state.Process_Id == [] || this.state.Process_Id.length == [0]) {
                alert(i18n.t("pleaseSelectProcess"));
                return false;
            }
            if (this.state.BOILIValue == [] || this.state.BOILIValue.length == [0]) {
              alert(i18n.t("pleaseSelectBOILI"));
              return false;
          }
           this.setState({ datasetNames: [], chartData: [] });

             
            //  var OMID = this.state.OMIDNewValue == undefined ? 0 : this.state.OMIDNewValue;
            //  var processid = this.state.Process_Id== undefined ? 0 : this.state.Process_Id;
              var BOILIId = this.state.BOILIValue[0].value;
              var OMID=this.state.OMIDNewValue;
              var SCID = this.state.Process_Id;
              var DealID = this.state.ClientId;
           
            let metricData = {
              //"DealId":OMID,
                "DealId": DealID,
               // "RegionID": RegionID,
               // "SCId": processid,
               "SCId": SCID,
               // "DeliveryLocationId": DeliveryLocationID,
                "BOILIId": BOILIId,
                "Type": 1,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
           
            request("/Common/MetricComparisionforBOILI", 'POST', metricData)
                .then(response => {
                    var comparisionData = response.Table;
                    var graphData = [];

                    var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];

                    var labelArr = [];
                    var valArr = [];
                    var colorArr = [];
                    var dataset = [];

                    for (var cnt = 0; cnt < comparisionData.length; cnt++) {
                        labelArr.push(comparisionData[cnt].Name);

                        valArr.push(comparisionData[cnt].AsIs);
                        colorArr.push(color[cnt]);

                    }
                    dataset.push({
                        label: i18n.t('asIsValue'),
                        backgroundColor: colorArr,
                        data: valArr
                    });

                    this.setState({ datasetNames: labelArr, chartData: dataset, chartType: 1 });
                    this.BarChart();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

  WithInDealTrendReport() {
        try {
           if ((this.state.OMIDNewValue === "" || this.state.OMIDNewValue == null) && 
          (this.state.DealValue === "" || this.state.DealValue == null || this.state.DealValue.length === 0))
           {
          alert(i18n.t("pleaseselectOMID//client"));
          return false;
      }
            // if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
            //     alert(i18n.t("pleaseselectDeal"));
            //     return false;
            // }
            if (this.state.BOILIValue == [] || this.state.BOILIValue.length == [0]) {
                alert(i18n.t("pleaseSelectBOILI"));
                return false;
            }
            this.setState({ chartType: 2 });
           // var OMID = this.state.OMIDNewValue == undefined ? 0 : this.state.OMIDNewValue;
            var OMID=this.state.OMIDNewValue;
            var SCID = this.state.Process_Id;
            var DealID = this.state.ClientId;
            var processid = this.state.Process_Id == undefined ? 0 : this.state.Process_Id;
           // var DeliveryLocationID = this.state.DlvryLocValue[0].value == undefined ? 0 : this.state.DlvryLocValue[0].value;
            var BOILIId = this.state.BOILIValue[0].value;
            let metricData = {
              //"OMID" : OMID,
                "DealId": DealID,
                //"RegionID": RegionID,
                //"SCId": processid,
                "SCId": SCID,
              //"DeliveryLocationId": DeliveryLocationID,
                "BOILIId": BOILIId,
                "Type": 2,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.state.DealValue[0].label)
            // }
         
            request("/Common/MetricComparisionforBOILI", 'POST', metricData)
                .then(response => {
                    var trendData = response.Table;
                    var graphData = [];
                    var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];

                    var labelArr = [];

                    var uniqueLabelArr = [...new Set(trendData.map(item => item.SubmittedDate))];
                    var uniqueLegendArr = [...new Set(trendData.map(item => item.Name))];

                    var newArr = [];
                    for (var cntleg = 0; cntleg < uniqueLegendArr.length; cntleg++) {
                        var valArr = [];
                        for (var cntlbl = 0; cntlbl < uniqueLabelArr.length; cntlbl++) {

                            const found = trendData.some(el => el.Name === uniqueLegendArr[cntleg] &&
                                el.SubmittedDate === uniqueLabelArr[cntlbl]);

                            var found1 = trendData.find(function (element) {
                                return element.Name === uniqueLegendArr[cntleg] &&
                                    element.SubmittedDate === uniqueLabelArr[cntlbl];
                            });

                            if (found == true) {
                                valArr.push(found1.AsIs);
                            }
                            else {
                                valArr.push(NaN);
                            }
                        }
                        newArr.push({
                            label: uniqueLegendArr[cntleg],
                            backgroundColor: "transparent",
                            borderColor: color[cntleg],
                            pointBackgroundColor: color[cntleg],
                            data: valArr,
                            showLine: true,
                            spanGaps: true,
                            pointRadius: 5,
                            pointHoverRadius: 5
                        });
                    }

                    this.setState({ datasetNames: uniqueLabelArr, chartData: newArr, chartType: 2 });
                    this.LineChart();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

   

    LineChart() {
        try {
            $("#bar").css("display", "none");
            $("#line").css("display", "block");
            Chart.defaults.global.plugins.datalabels.display = false;
            var line_ctx = document.getElementById('line').getContext('2d');

            var lineChartData = {

                labels: this.state.datasetNames,
                datasets: this.state.chartData,

            }

            window.line_chart = new Chart(line_ctx, {
                type: 'line',
                data: lineChartData,
                options: {
                    elements: {
                        line: {
                            tension: 0,
                        }
                    },
                    tooltips: {
                        mode: 'point',
                    }, legend: {
                        display: true,
                        labels: {
                            fontFamily: "Comic Sans MS"
                        }
                    }, scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadPPT() {
        //For whole page export
        try {
            //window.tfo_event("PMM-D-26", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            window.scrollTo(0, 0);
            //For whole page export
            html2canvas(document.getElementById("graph-section")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');
                var ppt = new PptxGenJS();
                var slides = ppt.addSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.0, w: 9.0, h: 5.25 });
               console.log(ppt,"ppt")
                ppt.save(i18n.t('bOILITrendReport'));
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

  
  
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
      console.log(this.state.OMIDNewValue,"Omid");
      console.log(this.state.Process_Id,"Process");
      console.log(this.state.BOILIId,"BOILI");

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page">...</span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetric")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">1</span></li>
                        <li><span title={i18n.t("lEADINGINDICATOR")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>

                    <Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back">
                        <img src={backArrowIcon} />{i18n.t('bACKTOHOME')} </a>
                    </Link>
                    <Link className="menu2 pull-left" to="/PMMReports">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('BackToViewDealWiseComparision')}</a>
                    </Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <section className="multiple-tabs-section no-padding-lr">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentHome">{i18n.t('submittedAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentDraft">{i18n.t('SavedDraftAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="active" to="/PMMReports">{i18n.t('reports')}</Link>
                                    </li>
                                </ul>
                            </section>
                            <h4>{i18n.t('FILTERS')}</h4>
                            {/* <div className="box">
                                <div className="box-in viewPrj-filter">
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_dealName"><span style={{ color: "red" }}>*</span>{i18n.t('deal_Name')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDeal" name="form-field-name" labelKey="label" onChange={this.SelectDeal.bind(this)} options={this.state.lstDeal} selected={this.state.DealValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_clientBusinessRegion"><span style={{ color: "red" }}>*</span>{i18n.t('clientBusinessRegion')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlRegion" name="form-field-name" labelKey="label" onChange={this.SelectRegion.bind(this)} options={this.state.lstRegion} selected={this.state.RegionValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_accentureDeliveryLocation"><span style={{ color: "red" }}>*</span>{i18n.t('accentureDeliveryLocation')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDlvryLoc" name="form-field-name" labelKey="label" onChange={this.SelectDlvryLoc.bind(this)} options={this.state.lstDlvryLoc} selected={this.state.DlvryLocValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_serviceComponent"><span style={{ color: "red" }}>*</span>{i18n.t('serviceComponent')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlServiceComp" name="form-field-name" labelKey="label" onChange={this.SelectServiceComponent.bind(this)} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-4">
                                            <label id="lbl_BOI/LI"><span style={{ color: "red" }}>*</span>BOI/LI</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlBOILI" name="form-field-name" labelKey="label" onChange={this.SelectBOILI.bind(this)} options={this.state.lstBOILI} selected={this.state.BOILIValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                </div>
                            </div> */}
                             <div className="box">
                <div className="box-in viewPrj-filter">
                  <span>
                    <div className="form-group col-sm-2">
                      <label id="lbl_OMID">
                        <span style={{ color: "red" }}>*</span>
                        {i18n.t("OMID")}
                      </label>

                      {/* <ReactSelect ref="ddlDeal" id="ddl_Deal_ViewPreviousAssessmentSubmitted" name="form-field-name" options={this.state.lstDeal} onChange={this.SelectDeal.bind(this)} value={this.state.DealValue} clearable={false} /> */}
                      <input
                        ref="ddlOMId"
                        id="ddl_omid"
                        className="form-control"
                        type="text"
                        maxLength="100"
                        value={this.state.OMIDNewValue}
                        onChange={(e) => {
                          this.handleOMIDChange(e);
                        }}
                      />
                      <br />
                      <button
                        className="btn btn-primary"
                        id="btn_search"
                        onClick={this.ValidateOMID.bind(this)}
                      >
                        {i18n.t("OM Search")}
                      </button>
                      <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>

                    </div>
                  </span>

                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                        {i18n.t("Client Name")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')} 
                       id="ddl_Deal_ViewPreviousAssessmentSubmitted"
                        ref="ddlDeal" name="form-field-name" 
                        labelKey="label" 
                        onChange={this.SelectDeal.bind(this)} 
                        options={this.state.lstDeal} 
                        selected={this.state.DealValue}
                         filterBy={this.filterCallbackTypeahead.bind(this)} 
                         placeholder={i18n.t("selectPlace")} /> 
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                        {i18n.t("Market")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMarket.bind(this)}
                    options={this.state.lstMarket} 
                    selected={this.state.MarketValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Main Offering")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMainOffering.bind(this)}
                    options={this.state.lstMainoffering} 
                    selected={this.state.MainOfferingValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Offering/SSG")}
                      </label>
                     
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectCapability.bind(this)}
                    options={this.state.lstCapability} 
                    selected={this.state.CapabilityValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                   
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_tower">{i18n.t("Sub Offering")}</label>
                     
                      
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectTower.bind(this)}
                    options={this.state.lstTower} 
                    selected={this.state.TowerValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                     <div className="form-group col-md-2">
                      <label id="lbl_serviceComponent">
                        {i18n.t("Process")}
                      </label>
                     
                       <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                   onChange={this.SelectProcess.bind(this)}
                    options={this.state.lstprocess} 
                    selected={this.state.ProcessValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />  
                        
                        
                    </div> 
                  </span>
                  <span>
                   <div className="form-group col-md-2">
                   <label id="lbl_BOI/LI"><span style={{ color: "red" }}>*</span>BOI/LI</label>
                   <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlBOILI" name="form-field-name" labelKey="label" onChange={this.SelectBOILI.bind(this)} options={this.state.lstBOILI} selected={this.state.BOILIValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                    </div>
                   </span>

                </div>
              </div>

                            <h4 className="btn-group-md">{i18n.t('BOILITrendR')}
                                <a type="button" className="btn btn-info pull-right" onClick={this.ClearFilter.bind(this)}>{i18n.t('clear')}</a>
                                <input id="txt_acrossDealComparision" type="button" className="btn btn-danger pull-right" value={i18n.t("acrossDealComparision")} title={i18n.t("PleaseselectOMID")} onClick={this.AcrossDealComparision.bind(this)} />
                                <a type="button" className="btn btn-success pull-right" onClick={this.WithInDealTrendReport.bind(this)} >{i18n.t('withinDealtrend')}</a>
                                <a type="button" title={i18n.t("downloadgraphsinppt")} className="btn btn-info pull-right" onClick={this.downloadPPT}><img src={powerpointIcon} /> {i18n.t('downloadasPPT')}</a>
                            </h4>
                            <div className="AsmtData viewprevassmt">

                                <div id="graph-section" className="box">
                                    <div className="row" >
                                        <div className="col-sm-12" style={{ "margin": "auto" }}>
                                            <div id="customLegendDiv"></div>
                                            {this.state.chartType = 1 ? <div id="dvBar"><canvas id="bar" width="600" height="200"> </canvas></div> : null}
                                            {this.state.chartType = 2 ? <div id="dvLine"><canvas id="line" width="600" height="200"> </canvas></div> : null}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

BOILITrendReport = connect(mapStateToProps)(withRouter(BOILITrendReport))
export default BOILITrendReport;